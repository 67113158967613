import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import logo from './assets/logo.png';

function MainComponent() {
  const { id } = useParams();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const userAgent =
      window.navigator.userAgent || window.navigator.vendor || window.opera;
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      window.location.href = 'https://apps.apple.com/app/id1599299074';
    } else if (/Android/i.test(userAgent)) {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.swyvery.user';
    } else {
      setErrorMessage(
        'App could not be opened, and no compatible store is available.'
      );
    }
  }, [id]);

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
  };

  const logoStyle = {
    maxWidth: '100%',
    height: '20vh',
    objectFit: 'contain',
  };

  if (window.innerWidth <= 768) {
    logoStyle.height = '15vh';
  }

  return (
    <div style={containerStyle}>
      <div>
        <img src={logo} alt='Logo' style={logoStyle} />
      </div>
      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );
}

export default MainComponent;
